import * as bootstrap from 'bootstrap';

interface TooltipElement extends Element {
    tooltip?: bootstrap.Tooltip;
}

interface CollapseElement extends Element {
    collapse?: bootstrap.Collapse;
}

// Initialize tooltips
const tooltipTriggerList = document.querySelectorAll<TooltipElement>('[data-bs-toggle="tooltip"]');
Array.from(tooltipTriggerList).forEach(element => {
    element.tooltip = new bootstrap.Tooltip(element);
});

// Initialize all collapse elements
const collapseElementList = document.querySelectorAll<CollapseElement>('.collapse');
Array.from(collapseElementList).forEach(element => {
    element.collapse = new bootstrap.Collapse(element, {
        toggle: false
    });
});

// Handle rotate icons for collapse elements
document.querySelectorAll<HTMLElement>('[data-bs-toggle="collapse"]').forEach((button) => {
    button.addEventListener('click', () => {
        const target = button.querySelector<HTMLElement>('.rotate-icon');
        if (target) {
            const targetId = target.getAttribute('data-bs-target');
            if (targetId) {
                const collapseElement = document.querySelector<HTMLElement>(targetId);
                if (collapseElement) {
                    // Add show class after a small delay to allow for the collapse animation
                    setTimeout(() => {
                        target.classList.toggle('show', collapseElement.classList.contains('show'));
                    }, 50);
                }
            }
        }
    });
});